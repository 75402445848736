import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { languages } from './languages';

import da from '../../translations/locale-da.json';
import de from '../../translations/locale-de.json';
import en from '../../translations/locale-en.json';
import es from '../../translations/locale-es.json';
import fr from '../../translations/locale-fr.json';
import hi from '../../translations/locale-hi.json';
import it from '../../translations/locale-it.json';
import ja from '../../translations/locale-ja.json';
import ko from '../../translations/locale-ko.json';
import nl from '../../translations/locale-nl.json';
import no from '../../translations/locale-no.json';
import pl from '../../translations/locale-pl.json';
import pt from '../../translations/locale-pt.json';
import sv from '../../translations/locale-sv.json';
import ru from '../../translations/locale-ru.json';
import zh from '../../translations/locale-zh.json';

languages['da']['resources'] = da;
languages['de']['resources'] = de;
languages['en']['resources'] = en;
languages['es']['resources'] = es;
languages['fr']['resources'] = fr;
languages['hi']['resources'] = hi;
languages['it']['resources'] = it;
languages['ja']['resources'] = ja;
languages['ko']['resources'] = ko;
languages['nl']['resources'] = nl;
languages['no']['resources'] = no;
languages['pl']['resources'] = pl;
languages['pt']['resources'] = pt;
languages['sv']['resources'] = sv;
languages['ru']['resources'] = ru;
languages['zh']['resources'] = zh;

// Construct the resources object based on active languages
const resources = Object.keys(languages)
  .filter((key) => languages[key].active)
  .reduce((acc, key) => {
    acc[key] = { translation: languages[key].resources };
    return acc;
  }, {});

const determineLanguage = () => {
  if (typeof window !== 'undefined') {
    const pathSegments = window.location.pathname.split('/').filter(Boolean);
    const currentLang = pathSegments[0];
    if (Object.keys(languages).includes(currentLang)) {
      return currentLang;
    }
  }
  return 'en'; // Default language if no match
};

i18n.use(initReactI18next).init({
  resources: resources,
  fallbackLng:
    Object.keys(languages).find((key) => languages[key].default) || 'en', // Default language
  lng: determineLanguage(),
  debug: true,
  keySeparator: false,

  interpolation: {
    escapeValue: false, // React already safes from xss
  },
});

export default i18n;
