const languages = {
  // ar: { code: "ar", lng_title_native: "عربي"},
  // bn: { code: "bn", lng_title_native: "বাংলা", active: true },
  // ca: { code: "ca", lng_title_native: "Català", active: true },
  // cs: { code: "cs", lng_title_native: "Česky", active: true },
  da: { code: "da", lng_title_native: "Dansk", active: true },
  de: { code: 'de', lng_title_native: 'Deutsch', active: true },
  en: { code: 'en', lng_title_native: 'English', active: true, default: true },
  es: { code: 'es', lng_title_native: 'Español', active: true },
  // fi: { code: "fi", lng_title_native: "Suomi", active: true },
  fr: { code: 'fr', lng_title_native: 'Français', active: true },
  // hr: { code: "hr", lng_title_native: "Hrvatski" },
  // hu: { code: "hu", lng_title_native: "Magyar", active: true },
  it: { code: 'it', lng_title_native: 'Italiano', active: true },
  ja: { code: 'ja', lng_title_native: '日本語', active: true },
  ko: { code: 'ko', lng_title_native: '한국어', active: true },
  nl: { code: 'nl', lng_title_native: 'Nederlands', active: true },
  no: { code: "no", lng_title_native: "Norsk", active: true },
  pl: { code: 'pl', lng_title_native: 'Polskie', active: true },
  pt: { code: 'pt', lng_title_native: 'Portuguese', active: true },
  // "pt-br": { code: "pt-br", lng_title_native: "Portuguese (BR)", active: true },
  ru: { code: 'ru', lng_title_native: 'Русский', active: true },
  sv: { code: "sv", lng_title_native: "Svenska", active: true },
  // sk: { code: "sk", lng_title_native: "Slovák" },
  // uk: { code: "uk", lng_title_native: "Український", active: true },
  // he: { code: "he", lng_title_native: "" }, // deactivated
  hi: { code: "hi", lng_title_native: "हिंदी", active: true  },
  // vi: { code: "vi", lng_title_native: "" }, // deactivated
  zh: { code: "zh", lng_title_native: "漢語", active: true },
};

module.exports = { languages };
